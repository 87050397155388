<template>
	
    <!--
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/avaa">Avaa</router-link> |
      <router-link to="/admin/rustamustamustamustamustamusta">Admin</router-link>
    </div>
    -->

    <router-view />

</template>

<style>
* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
}
html,body,#app {
    height: 100%;
}
body {
    margin: 0;
    padding: 15px;
    font-family: sans-serif;
    background: #efefef;
    color: #272727;
    font-size: 13px;
    font-weight: 600;
}
img, svg {
    max-width: 100%;
    height: auto;
    border: 0;
}
[v-cloak] {
  display: none;
}
button.btn {
    background: #fff;
    border-radius: 3px;
    padding: 10px;
    border: none;
    cursor: pointer;
    color: #000;
    font-weight: bold;
}
</style>