import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import Open from "@/components/Open.vue";
import Admin from "@/components/Admin.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/avaa/:token?",
    name: "Avaa",
    component: Open,
  },
  {
    path: "/admin/:token?",
    name: "Admin",
    component: Admin,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;