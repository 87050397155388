<template>

	<div class="container">

		<img class="logo" src="@/assets/noutorauta-logo.png">

		<button @click="getUserGeoLocation" class="btn">Avaa ovi</button>

		<div class="terminal">

			<template v-if="data.getting_location">
				<p>Paikannetaan...</p>
			</template>

			<template v-if="data.user_location">
				<p>Paikannus onnistui</p>
			</template>

			<template v-if="data.error_string">
			    <p style="color:red;">{{data.error_string}}</p>
			</template>

			<template v-if="data.message_string">
			    <p style="color:#2daf2f;">{{data.message_string}}</p>
			</template>
		</div>

	</div>

</template>

<script setup>
import axios from 'axios'
import router from '@/router'
import { useRoute } from 'vue-router'
import { reactive, onMounted, computed } from 'vue'

const route = useRoute()
const apiurl = 'https://comnisol.herokuapp.com/api-v1';

const data = reactive({
	token:null,
	user_location:null,
	getting_location:false,
	error_string:null,
	message_string:null
});

function getUserGeoLocation() {

	if(!("geolocation" in navigator)) {
      data.error_string = 'Paikannus ei onnistunut'
      return
    }

    //console.log('getting loc')

    data.getting_location = true

    navigator.geolocation.getCurrentPosition(pos => {
      data.getting_location = false;
      data.user_location = pos;

      doGpsOpen()

    }, err => {
      data.getting_location = false;
      data.error_string = err.message;
    })
}

function getErrorStatus(errorcode) {
	
	//console.log(errorcode)

	switch(errorcode) {
		case 400:
			return 'invalid params'
		break
		case 404:
			return 'invalid token'
		break
		case 403:
			return 'Olet liian kaukana, yritä uudelleen'
		break
		case 401:
			return 'Avain vanhentunut'
		break
		default:
			return 'other'

	}
}

function doGpsOpen() {
	
	let json = JSON.stringify({
		token: data.token,
		gpsLat: data.user_location.coords.latitude,
		gpsLng: data.user_location.coords.longitude
	})

	axios.post(apiurl + '/gpsopen', json)
	.then((response) => {
		if(response.status==200) {
			data.message_string = 'Ovi on avattu'
		}
		//console.log(response)
	})
	.catch((error) => {
		//console.log(error.response)
		data.error_string = getErrorStatus(error.response.status)
	})

}

onMounted(() => {

	if(!route.params.token) {
		router.push('/')
		return
	}

	data.token = route.params.token

})

</script>

<style scoped>
	.container {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
	}
	button {
		margin: 20px auto;
		display: block;
	}
	.logo {
		max-width: 50%;
    margin: 0 auto 50px;
    display: block;
	}
	.terminal {
		background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 5px;
    text-align: center;
    margin-top: 20px;
	}
	.terminal:empty {
		display: none;
	}
	.terminal p {
		margin: 0 0 2px;
    padding: 6px 0 6px;
    border-bottom: 1px solid #e7e7e7;
	}
	.terminal p:last-child {
		border: none;
	}
	.btn {
		background: #004481;
    border-radius: 4px;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
	}
</style>



