<template>

	<div v-if="data.order_completed" class="container">
		<h2>Tilauksen käsittely valmis</h2>
		<p>Voit nyt sulkea selainukkunan</p>
	</div>
	
	<div v-else class="container">

		<h2>Valitse käsiteltävä tilaus</h2>
		<template v-for="order in data.available_orders">
			<button @click="setActiveOrder(order.id)" class="selectorder btn">{{order.id}}</button>
		</template>
		
		<div v-if="data.available_locks.length" class="locks-container">

			<h2>Valitse avattavat ovet</h2>
		
			<div class="locks">

				<template v-for="(lock,index) in data.available_locks">
					
					<template v-if="lock.status == 'available'">
						<div :class="[{active: lock.isselected}, 'lockitem']">
							<a href="#" class="doorno" @click.prevent="selectLock(index)">Ovi #{{ lock.id }}</a>
							<button @click="cancelLockSelect(index)">X</button>
						</div>
					</template>

					<template v-else>
						<div class="lockitem taken"><div class="doorno">Ovi #{{ lock.id }}</div></div>
					</template>

				</template>

			</div>

			<div v-if="getSelectedLocks().length" class="openlocks" @click="openLocks">
				<div>
					Valitut lukot {{ getSelectedLocks().join(', ') }}
				</div>
				<button class="btn delivery" :disabled="data.create_delivery_clicked" @click="createDelivery">Viimeistele tilaus</button>
			</div>

		</div>

	</div>

</template>

<script setup>
import axios from 'axios'
import router from '@/router'
import { useRoute } from 'vue-router'
import { reactive, onMounted, computed } from 'vue'

const route = useRoute()
const apiurl = 'https://comnisol.herokuapp.com/api-v1';
const data = reactive({
	token:'',
	active_order:'',
	available_orders:[],
	available_locks:[],
	active_lock_index:0,
	order_completed:false,
	create_delivery_clicked: false
});

/*
const selected_locks = computed(function(){
	return data.available_locks.filter(function(item) {
			return item.isselected;
	}).map(function (item) {
      return item.id;
  })
})
*/

function getSelectedLocks() {
	return data.available_locks.filter(function(item) {
			return item.isselected;
	}).map(function (item) {
      return item.id;
  })
}

function cancelLockSelect(index) {
	let current_lock = data.available_locks[index]
	current_lock.isselected = false
}

function selectLock(index) {

	let current_lock = data.available_locks[index]
	if(!current_lock.isselected && getSelectedLocks().length < 3) {
		current_lock.isselected = true
		openSingleLock(current_lock.id)
		//console.log(current_lock)
	} else {
		//console.log('lock allready selected or maximum locks reached')
	}

}

function createDelivery() {

	data.create_delivery_clicked = true
	
	if(getSelectedLocks().length==0) {
		//console.log('no locks selected')
		data.create_delivery_clicked = false
		return
	}

	// Add lock:1 (main door) allways to create delivery
	let modified_selected_locks = getSelectedLocks()
	modified_selected_locks.unshift(1)

	let json = JSON.stringify({
		token: data.token,
		orderid: data.active_order,
		openRelays: modified_selected_locks
	})

	axios.post(apiurl + '/createdelivery', json)
	.then((response) => {
		//console.log(response)
		if(response.status == 200) {
			data.order_completed = true
		}
	})
	.catch((error) => {
		//console.log(error)
	})

}

function openSingleLock(lock_id) {

	let json = JSON.stringify({
		token: data.token,
		openRelays: [lock_id]
	})

	axios.post(apiurl + '/updatelocks', json, {
	  headers: {
	    'Content-Type': 'application/json'
	  }
	})
	.then((response) => {
		//console.log(response)
	})
	.catch((error) => {
		//console.log(error)
	})

}

function getAvailableOrders() {

	//console.log('GOT TOKEN: ' + data.token)
	
	if(data.token=='') {
		router.push('/')
		return
	}

	axios.get(apiurl + '/readavailableorders/' + data.token)
	.then((response) => {
		data.available_orders = response.data
	})
	.catch((error) => {
		//console.log(error)
		router.push('/')
	})
}

function getAvailableLocks() {

	axios.get(apiurl + '/readavailablelocks/' + data.token)
	.then((response) => {
		
		var templocklist = []

		for (var i = 1; i <= response.data.relays; i++) {
			let available = response.data.available.find(lock => lock.id === i);
			if(available) {
				available.status = 'available'
				templocklist.push(available);
			} else {
				available = {
					id:i,
					value:0,
					status:'taken'
				}
				templocklist.push(available);
			}
		}

		//console.log(templocklist)
		data.available_locks = templocklist

	})
	.catch((error) => {
		//console.log(error)
	})
}

function setActiveOrder(orderid) {
	data.active_order = orderid
	getAvailableLocks()
}

onMounted(() => {

	if(!route.params.token) {
		router.push('/')
		return
	}

	data.token = route.params.token
	router.push('/admin')

	getAvailableOrders()

})

</script>

<style scoped>
.container {
	position: relative;
	padding-bottom: 100px;
}
.selectorder {
	margin: 0 5px 5px 0;
}
.locks {
	margin-top: 20px;
	columns: 3;
}
.lockitem {
	position: relative;
	display: inline-block;
  width: 100%;
	margin-bottom: 5px;
	background: #fff;
	border-radius: 3px;
	cursor: pointer;
}
.lockitem:hover {
	box-shadow: 0 0 14px rgba(0,0,0,0.1);
}
.lockitem .doorno {
	display: block;
  padding: 10px;
  text-decoration: none;
  color: inherit;
}
.lockitem.active {
	background: #2961d9;
	color: #fff;
}
.lockitem button {
  position: absolute;
  right: 6px;
  top: 7px;
  pointer-events: none;
  opacity: 0.1;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 10px;
  background: white;
}
.lockitem.active button {
  pointer-events: initial;
  opacity: 1;
}
.lockitem.taken,
.lockitem.taken * {
	pointer-events: none;
	opacity: 0.4;
}
.openlocks {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  align-items: center;
  justify-content: space-between;
}
.openlocks .btn {
	background: #2961D8;
	border-radius: 3px;
	display: inline-block;
	padding: 10px 60px;
	border: none;
	cursor: pointer;
	color: #fff;
	font-weight: bold;
}
.delivery.btn:disabled,
.delivery.btn[disabled] {
    opacity: 0.6;
    pointer-events: none;
}
@media all and (max-width: 400px) {
	.locks {
		columns: 2;
	}
}
</style>
